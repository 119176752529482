import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  public readonly apiUrl = environment.API_BASE_URL;

  constructor(public http: HttpClient, public sanitizer: DomSanitizer) {
    // set token if saved in local storage
  }

  // Get all nft
  getAllNFT(email: String) {
    return this.http.get(`${this.apiUrl}propertyinfo/user/${email}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // create transaction
  createTransaction(transaction: any) {
    return this.http
      .post(`${this.apiUrl}propertyinfo/transactions`, transaction)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  // get transactions
  getTransactions() {
    return this.http.get(`${this.apiUrl}propertyinfo/transactions/me`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // Get all transactionlist
  getAllTransactionList(address: String) {
    return this.http
      .get(
        `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${environment.LRX_CONTRACT_ADDRESS}&address=${address}`
      )
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  // Get all nft
  updateNFT(nftId: String, nftBody: Object) {
    return this.http.patch(`${this.apiUrl}propertyinfo/${nftId}`, nftBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // claim propery
  claimProperty(property_id: string, ethAddress: string, data:any) {
    return this.http
      .post(`${this.apiUrl}propertyinfo/claim-request`, {
        property_id,
        ethAddress,
        data
      })
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  // create transfer
  createTransfer(data: any) {
    return this.http
      .post(`${this.apiUrl}transfer`, data)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
