import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
  public readonly apiUrl = environment.API_BASE_URL;

  constructor(public http: HttpClient, public sanitizer: DomSanitizer) {
    // set token if saved in local storage
  }

  // Get user by id
  getUserById(userId: String) {
    return this.http.get(`${this.apiUrl}users/${userId}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // Add New User
  updateUser(
    userId: String,
    userBody: Object
  ) {
    return this.http
      .patch(`${this.apiUrl}users/${userId}`, userBody)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

}
